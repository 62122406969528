<template>
  <v-row>
    <v-col cols="12">
      <h2>{{ $t('message_board.head') }}</h2>
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('message_board.head_list') }} </v-card-title>
        <v-card-actions class="pb-4 d-flex align-end">
          <v-spacer />
        </v-card-actions>
        <v-card-actions class="pt-0">
          <v-data-table mobile-breakpoint="0" style="width: 100%" :headers="headers" :items="itemsData"
            :no-data-text="$t('not_found')" :no-results-text="$t('not_found')" :options.sync="pagination"
            :server-items-length="pagination.totalItems" :items-per-page="pagination.rowsPerPage"
            :page.sync="pagination.page" :footer-props="{
              'items-per-page-options': [10, 25, 30, 40, 50],
            }" :loading="loading" :search="search">
            <template v-slot:[`item.status`]="{ item }">
              <span v-if="item.status && item.status === 'approve'" class="success--text">{{ $t('message_board.approved')
              }}</span>
              <span v-else-if="item.status && item.status === 'notApprove'" class="error--text">{{
                $t('message_board.not_approved') }}</span>
              <span v-else class="warning--text">{{ $t('message_board.wait_approved') }}</span>
            </template>
            <template v-slot:[`item.created_at`]="{ item }">
              <span>{{ formatDate(item.created_at) }}</span>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex flex-row align-center justify-center ma-0">
                <v-btn :disabled="item.status !== 'wait'" class="success mx-1"
                  @click="uppdateMessageBoard('approve', item)">
                  <v-icon medium color="white">mdi-check</v-icon>
                </v-btn>
                <v-btn :disabled="item.status !== 'wait'" class="error mx-1"
                  @click="uppdateMessageBoard('notApprove', item)">
                  <v-icon medium color="white">mdi-close</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DatePickerInput from '@/components/input/DatePickerInput.vue'
import SelectInput from '@/components/input/SelectInput.vue'
import moment from 'moment'
import store from '@/store'
export default {
  components: {
    DatePickerInput,
    SelectInput,
  },

  data() {
    return {
      menu: false,
      showDetail: false,
      toggle_exclusive: 0,
      dateFrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      dateTo: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      date: null,
      currentMemberDetail: {},
      search: '',
      loading: false,
      headers: [
        {
          text: this.$t('message_board.header1'),
          value: 'phone',
          width: '90px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('message_board.header2'),
          value: 'message',
          width: '200px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('message_board.header3'),
          value: 'created_at',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('message_board.header4'),
          value: 'status',
          width: '95px',
          align: 'center',
          sortable: false,
        },
        {
          text: this.$t('message_board.header5'),
          value: 'actions',
          width: '40px',
          align: 'center',
          sortable: false,
        },
      ],
      itemsData: [],
      pagination: {
        page: 1,
        rowsPerPage: 25,
        totalItems: 0,
      },
    }
  },
  async created() {
    this.addLogPage()
    // await this.getMessageBoard(this.pagination.page, this.pagination.rowsPerPage)
  },
  watch: {
    pagination: {
      async handler(newValue, oldValue) {
        let cont = false
        if (oldValue.page !== newValue.page) cont = true
        if (oldValue.itemsPerPage !== newValue.itemsPerPage) cont = true
        if (oldValue.sortBy !== newValue.sortBy) cont = true
        if (oldValue.sortDesc !== newValue.sortDesc) cont = true
        if (cont) {
          this.loading = true
          await this.getMessageBoard(this.pagination.page, this.pagination.rowsPerPage)
          this.loading = false
        }
      },
      deep: true,
    },
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await fetch('https://jsonip.com/').then(res =>
          res.json().then(
            el =>
            (data = {
              ip: el.ip,
              name: 'คอมเมนท์',
              url: window.location.href,
              detail: 'ระบบจัดการคอมเมนท์',
              admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
            }),
          ),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    async getMessageBoard(page, row) {
      this.itemsData = []
      try {
        const result = await this.$store.dispatch('getMessageBoard', {
          page: page,
          rows: row,
        })
        this.itemsData = result.data
        this.pagination.totalItems = result.count
      } catch (e) {
        if (
          !e.message.includes('Unexpected token') ||
          !e?.includes('Unexpected token') ||
          !e.message.includes('Unexpected identifier')
        ) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
    },
    async uppdateMessageBoard(status, item) {
      try {
        const data = { message_id: item.id, status: status }
        await this.$store.dispatch('uppdateMessageBoard', data)

        this.$swal.fire(this.$t('message_board.alert_complete'), '', 'success')
        await this.getMessageBoard(this.pagination.page, this.pagination.itemsPerPage)
        this.loading = false
      } catch (e) {
        if (
          !e.message.includes('Unexpected token') ||
          !e?.includes('Unexpected token') ||
          !e.message.includes('Unexpected identifier')
        ) {
          this.$swal.fire({
            icon: 'error',
            title: e.message,
          })
        }
      }
    },
    async deleteMessageBoard(item) {
      this.$swal
        .fire({
          icon: 'warning',
          title: this.$t('alert'),
          text: `${this.$t('alert_list.list11')} ${item.message}`,
          showCancelButton: true,
          confirmButtonText: this.$t('confirm'),
          cancelButtonText: this.$t('cancel'),
        })
        .then(async result => {
          if (result.isConfirmed) {
            await this.$store.dispatch('deleteMessageBoard', item.id)

            this.$swal.fire(this.$t('alert_list.list12'), '', 'success')
            await this.getMessageBoard(this.pagination.page, this.pagination.itemsPerPage)
            this.loading = false
          }
        })
    },

    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>
